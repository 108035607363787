<script setup lang="ts">
const { signIn } = useAuth();
</script>

<template>
  <UButton
    icon="i-logos-google-icon"
    color="white"
    block
    size="xl"
    @click="signIn('google')"
  >
    Continue with Google
  </UButton>
</template>
