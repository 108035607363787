<script setup lang="ts">
import type { FormSubmitEvent } from '#ui/types';
import { z } from 'zod';

const { signIn } = useAuth();

const sso = ref(false);

const showSso = () => {
  sso.value = true;
};

const schema = z.object({
  name: z.union([z.literal('Revantage'), z.literal('revantage')], {
    errorMap: () => ({ message: 'Invalid team name.' }),
  }),
});

type Schema = z.output<typeof schema>;

const state = reactive({
  name: undefined,
});

async function onSubmit(event: FormSubmitEvent<Schema>) {
  await signIn('okta');
}
</script>

<template>
  <UButton v-if="!sso" color="white" block size="xl" @click="showSso">
    Continue with SSO
  </UButton>
  <UForm
    v-else
    :schema="schema"
    :state="state"
    class="space-y-4"
    @submit="onSubmit"
  >
    <UFormGroup
      label="Team name"
      name="name"
      size="xl"
      :ui="{ label: { base: 'font-normal text-gray-800' } }"
    >
      <UInput v-model="state.name" />
    </UFormGroup>

    <UButton type="submit" block size="xl"> Continue</UButton>
  </UForm>
</template>
